// Step 1: Import React
//import * as React from 'react'
import React, { useEffect }  from 'react'
import Layoutp from '../components/Layoutp'
import { StaticImage } from 'gatsby-plugin-image'

// Step 2: Define your component
const AboutPage = () => {
  useEffect(() => {
    console.log('useEffect');
    var element = document.getElementById('home');
    element.style.background = 'none';
    element.style.height = 'auto';
    element.style['min-height'] = '0px';
  },[]);
  return (
    <Layoutp pageTitle="Sakura Hall 2024">
	  <h3 style={{"margin-top": "-60rem", "text-align": "center", "color": "white"}}>『2024.2.16（金）2024守屋純子オーケストラ定期公演/Tribute to Carlla Bley and More....』</h3>
            <h3 style={{ "text-align": "center", "color": "white"}}>HP販売</h3>
            <div className=''>
　　　　　　　    <StaticImage className="main-eye-catch-l" style={{"width":"50%", "margin-right": "25%", "margin-left": "25%" }}
　　　　　　        alt="定期公演"
　　　　　　        src="../images2/2024定期公演表.jpg"
　　　　　　      />
                <p style={{ "text-align": "center", "color": "white" }}><b>2024.2/16(金) 渋谷さくらホール　　open:18:15--  /Start: 19:00--</b><br/> 
               一般4500円、学生2500円</p>
                <div style={{ "text-align": "center", "color": "white", "width": "50%", "margin-right": "auto", "margin-left": "auto" }}>
                <p>
                  お問合せ：0570-00-3337　サンライズプロモーション東京（平日12-15時）<br/>
                  フライヤーは以下からDLできます。<a href="https://jmo.junkomoriya.com/wp-content/uploads/2024定期公演表.jpg">2024.2.16コンサートチラシ表</a>、<a title="チラシ裏" href="https://jmo.junkomoriya.com/wp-content/uploads/2024定期公演裏.jpg">チラシ裏</a><br/>
                  e+,チケットぴあ、ローソンチケットでも販売しております。チラシ裏面をごらんください。<br/>（ただし、このHP販売の方が、良いお席を指定できます）
                </p>
                <p>
                </p>
                <p style={{ "text-align": "center", "color": "white", "text-indent": "1em" }}>
	  2023年10月17日、Carla Bleyが亡くなりました。私にとっては、作曲家・ピアニストとして最も影響を受けたミュージシャンの一人で、直接お話ししたこともあり、彼女の音楽制作に対する真摯な姿勢をいつも見習いたいと思ってきました。今回は彼女のOne &amp; Onlyの世界を、私なりに再構築してお届けします。<br/>
         また、同年3月に亡くなった巨匠・Wayne Shorterの作品やスタンダード・オリジナルの新曲も演奏します。<br/>
         この公演でしか聴けない音に、どうぞご期待ください。
                </p>
                <h3 style={{ "color": "white" }}>＜HP販売について＞</h3>
                <p style={{ "text-align": "left" }}>
チケットはチケットぴあ等でもお求めになれますが、<span style={{ "color": "red", "font-weight": "bold" }}>最も良いお席は、このHPにて座席指定で御求めいただけます。学生券に関しては、このHPで2500円で承ります。</span><u>学生券は座席指定はできません。</u>また当日学生証の提示が必要です。
                </p>
                <p style={{ "text-align": "left" }}>
一般席・学生席とも、<a href="https://jmo.junkomoriya.com/?page_id=1100&ref=rp">こちらのフォーム</a>からお申し込みください。（ご希望の＜座席＞を1枚選ぶたびに、追加ボタンを押してください。）このフォームでうまく申し込めない場合は、ご希望のお席と送付先ご住所、支払い方法のご希望を守屋まで直接<a href="mailto:junkomry@mars.dti.ne.jp">メール</a>してください。
                </p>
                </div>
              </div>
              <hr/>
    </Layoutp>
  )
}

// Step 3: Export your component
export default AboutPage
